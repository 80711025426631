<template>
  <container style="max-width: 1000px;">
    <v-card-title class="pl-0">{{ $capitalize($tc("model.form_lead_new_lead_title")) }}</v-card-title>
    <v-row>
      <v-col cols="12" md="6">
        <v-card class="px-3 pb-3" height="100%" @click="selectInsurer(false)">
          <v-row class="fill-height">
            <v-col cols="4" xs="12" class="d-flex align-center">
              <v-img src="@/assets/blank_lead.svg" contain height="100px" />
            </v-col>
            <v-col>
              <v-card-title class="primary--text pl-0">{{ __('Criar', 'new_lead') }}</v-card-title>
              <v-card-subtitle class="pl-0 pt-1">{{ __('Formulário de criação de lead em branco para preenchimento manual', 'new_lead') }}</v-card-subtitle>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card class="px-3 pb-3" height="100%" @click="selectInsurer(true)">
          <v-row class="fill-height">
            <v-col cols="4" xs="12" class="d-flex align-center">
              <v-img src="@/assets/template_lead.svg" contain height="100px" />
            </v-col>
            <v-col>
              <v-card-title class="primary--text pl-0">{{ __('Importar e criar', 'new_lead') }}</v-card-title>
              <v-card-subtitle class="pl-0 pt-1">{{ __('Formulário de criação de lead pré-populado com as informações presentes no arquivo', 'new_lead') }}</v-card-subtitle>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <SelectInsurerDialog
      v-model="select_insurer_dialog"
      :import_lead="import_lead"
      @continue="redirect"
    />
  </container>
</template>

<script>
import SelectInsurerDialog from "@/components/Lead/Form/SelectInsurerDialog";
import i18n from '@/mixins/i18n';

export default {
  mixins: [i18n],
  components: { SelectInsurerDialog },
  data() {
    return {
      select_insurer_dialog: false,
      import_lead: false
    }
  },
  methods: {
    selectInsurer(import_lead = false) {
      this.import_lead = import_lead;
      this.select_insurer_dialog = true;
    },
    redirect(insurer) {
      if (this.import_lead) {
        this.$router.push({ 
          name: 'lead_new_import_file', 
          params: {
            insurer_id: insurer.id
          }
        });
      } else {
        this.$router.push({ 
          name: 'lead_new_blank', 
          params: {
            insurer_id: insurer.id
          }
        });
      }
    }
  }
}
</script>

<style>

</style>
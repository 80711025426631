<template>
  <v-dialog
    :value="value"
    @change="handleChange"
    @click:outside="cancel"
    max-width="800px"
  >
    <v-card>
      <v-app-bar flat>
        <v-toolbar-title>{{
          __("Selecionar seguradora", "new_lead")
        }}</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="cancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-list v-model="insurer">
        <v-list-item-group v-model="insurer">
          <template v-for="(item_insurer, index) in allowed_insurers">
            <v-list-item :key="item_insurer.id" :value="item_insurer">
              <v-list-item-avatar>
                <user-avatar
                  :user_name="item_insurer.name"
                  :avatar_url="null"
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ item_insurer.name }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon
                  v-if="insurer ? insurer.id == item_insurer.id : false"
                  color="primary"
                  >mdi-check</v-icon
                >
              </v-list-item-action>
            </v-list-item>
            <v-divider
              :key="'divider-' + item_insurer.id"
              v-if="index != allowed_insurers.length - 1"
            />
          </template>
        </v-list-item-group>
      </v-list>
      <v-card-actions class="">
        <v-spacer />
        <v-btn color="primary" @click="$listeners.continue(insurer)">
          {{ __("Continuar", "new_lead") }}
          <v-icon right>mdi-arrow-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { index as indexInsurers } from "@/services/insurers";
import UserAvatar from "@/components/UserAvatar";
import i18n from "@/mixins/i18n";

export default {
  mixins: [i18n],
  components: { UserAvatar },
  props: {
    value: Boolean,
    import_lead: Boolean,
  },
  data() {
    return {
      fetching_insurers: false,
      insurers: [],

      IMPORTABLE_INSURERS: ["501689168", "506333027"],

      insurer: null,
    };
  },
  computed: {
    allowed_insurers() {
      return this.insurers.filter((insurer) => {
        if (this.import_lead)
          return this.IMPORTABLE_INSURERS.some(
            (importable) => importable == insurer.fiscal_number
          );

        return true;
      });
    },
  },
  methods: {
    handleChange($event) {
      this.$emit("input", $event);
    },
    cancel() {
      this.$emit("input", false);
      this.insurer = null;

      if (this.$listeners.cancel) {
        this.$listeners.cancel();
      }
    },

    async fetchInsurers() {
      this.fetching_insurers = true;
      const response = await indexInsurers();

      this.insurers = response;
      this.fetching_insurers = false;
    },
  },
  created() {
    this.fetchInsurers();
  },
};
</script>

<style></style>
